import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="monsters-digester-doppelganger"></a><h2>Monsters</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Digester - Doppleganger</span>
    </h2>
    <h3><a id="digester"></a>DIGESTER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG59.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Medium <a href="monsterTypes.html#magical-beast" style={{
        "color": "black"
      }}>Magical Beast</a></span> <span className="stat-block"><b>Hit Dice</b>: 8d10+24 (68 hp)</span> <span className="stat-block"><b>Initiative</b>: +6</span> <span className="stat-block"><b>Speed</b>: 60 ft. (12 squares)</span> <span className="stat-block"><b>Armor Class</b>: 17 (+2 Dex, +5
natural), touch 12, flat-footed 15</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +8/+11</span> <span className="stat-block"><b>Attack</b>:
Claw +11 melee (1d8+4)</span> <span className="stat-block"><b>Full Attack</b>:
Claw +11 melee
(1d8+4)</span> <span className="stat-block"><b>Space/Reach</b>: 5 ft./5 ft.</span>
    <span className="stat-block"><b>Special Attacks</b>: Acid spray</span>
    <span className="stat-block"><b>Special Qualities</b>: Darkvision 60
ft., immunity to acid, low-light vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span>
    <span className="stat-block"><b>Saves</b>: Fort +9, Ref +10, Will +3</span>
    <span className="stat-block"><b>Abilities</b>: Str 17, Dex 15, Con
17, Int 2, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+9, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+6, <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
+21, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+6</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#improved-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Initiative</a>, <a href="featsAll.html#lightning-reflexes" style={{
        "color": "rgb(87, 158, 182)"
      }}>Lightning Reflexes</a></span> <span className="stat-block"><b>Environment</b>:
Warm forests</span> <span className="stat-block"><b>Organization</b>:
Solitary or pack
(3&ndash;6)</span> <span className="stat-block"><b>Challenge Rating</b>: 6</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 9&ndash;12 HD (Medium);
13&ndash;24 HD (Large)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>A digester stands about 5 feet tall and is 7 feet long from
snout to tail. It weighs about 350 pounds,</p>
    <h5>COMBAT</h5>
    <p className="initial">A digester is a hunting and eating machine.
When it is not hungry (which is rarely), it lies low and avoids most
other creatures. When hunting, it looks about for a likely target, then
charges forth and delivers a gout of acid. If the initial attack is
insufficient to kill the prey, the digester attacks with its hind feet
until it can spray acid again.</p>
    <p><b>Acid Spray (Ex)</b>: A digester can spray acid in a 20-foot
cone, dealing 4d8 points of damage to everything in the area. Once a
digester uses this ability, it can&rsquo;t use it again until 1d4
rounds
later. The creature can also produce a concentrated stream of acid that
deals 8d8 points of damage to a single target within 5 feet. In either
case, a DC 17 Reflex save halves the damage. The save DC is
Constitution-based.</p>
    <p><b>Skills</b>: A digester&rsquo;s coloration gives it a +4
racial
bonus on Hide checks. It also has a +4 racial bonus on Jump checks.</p>
    <h3><a id="dinosaur"></a>DINOSAUR</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Dinosaurs come in many sizes and shapes.
Bigger varieties have drab coloration, while smaller dinosaurs have
more colorful markings. Most dinosaurs have a pebbly skin texture.</p>
    <h5>COMBAT</h5>
    <p className="initial">Dinosaurs take full advantage of their size
and speed. The swift carnivores stalk prey, staying hidden in cover
until they can get into charge range and rush to the attack. Herbivores
frequently overrun and trample their opponents.</p>
    <h5><a id="deinonychus-dinosaur"></a>DEINONYCHUS</h5>
    <span className="size-block">Medium <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span>
    <span className="stat-block"><b>Hit
Dice</b>: 4d8+16 (34 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: 60 ft. (12 squares)</span>
    <span className="stat-block"><b>Armor Class</b>: 17 (+2 Dex,
+5 natural), touch 12, flat-footed 15</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +3/+7</span> <span className="stat-block"><b>Attack</b>:
Talons +7 melee (1d8+4)</span> <span className="stat-block"><b>Full Attack</b>:
Talons +7 melee
(1d8+4) and 2 foreclaws +2 melee (1d3+2) and bite +2 melee (2d4+2)</span>
    <span className="stat-block"><b>Space/Reach</b>: 5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#pounce" style={{
        "color": "rgb(87, 158, 182)"
      }}>Pounce</a></span>
    <span className="stat-block"><b>Special Qualities</b>: Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +8,
Ref +6, Will +2</span> <span className="stat-block"><b>Abilities</b>: Str
19, Dex 15, Con
19, Int 2, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+12, <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
+26, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+10, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+10, <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> +10</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#run" style={{
        "color": "rgb(87, 158, 182)"
      }}>Run</a>, <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a></span>
    <span className="stat-block"><b>Environment</b>: Warm
forests</span> <span className="stat-block"><b>Organization</b>: Solitary,
pair, or
pack (3&ndash;6)</span> <span className="stat-block"><b>Challenge Rating</b>:
3</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 5&ndash;8 HD (Medium)</span> <span className="stat-block"><b>Level Adjustment</b>: &mdash;</span>
    <p>A deinonychus is bright green along its back and flanks, with
a much lighter shade of the same color on its underside. The body has
darker spots or stripes. Its tail extends straight out behind itself,
held aloft by an intricate structure of bony supports, thus allowing
its weight to be carried entirely by the back legs. It weighs about 600
pounds.</p>
    <h6>Combat</h6>
    <p className="initial">A deinonychus uses a combination of speed,
grasping forearms, large teeth, and hind legs with ripping talons. It
hunts by running at prey, leaping, and ripping with its rear talons as
it claws and bites. The talons count as one attack. A deinonychus has a
relatively large brain for a dinosaur, and its pack hunts with cunning
tactics.</p>
    <p><b>Pounce (Ex)</b>: If a deinonychus charges, it can make a
full attack.</p>
    <p><b>Skills</b>: A deinonychus has a +8 racial bonus on Hide,
Jump, Listen, Spot, and Survival checks.</p>
    <h5><a id="elasmosaurus-dinosaur"></a>ELASMOSAURUS</h5>
    <span className="size-block">Huge <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 10d8+66 (111 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares),
swim
50 ft.</span> <span className="stat-block"><b>Armor Class</b>: 13
(&ndash;2
size, +2 Dex,
+3 natural), touch 10, flat-footed 11</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +7/+23</span> <span className="stat-block"><b>Attack</b>:
Bite +13 melee (2d8+12)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +13 melee
(2d8+12)</span> <span className="stat-block"><b>Space/Reach</b>: 15 ft./10
ft.</span> <span className="stat-block"><b>Special Attacks</b>: &mdash;</span>
    <span className="stat-block"><b>Special Qualities</b>: Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +15,
Ref +9, Will +4</span> <span className="stat-block"><b>Abilities</b>: Str
26, Dex 14, Con
22, Int 2, Wis 13, Cha 9</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
&ndash;4*, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+4, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+9, <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
+16</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>,
      <a href="featsAll.html#great-fortitude" style={{
        "color": "rgb(87, 158, 182)"
      }}>Great Fortitude</a>, <a href="featsAll.html#toughness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a>
(2)</span> <span className="stat-block"><b>Environment</b>: Warm
aquatic</span> <span className="stat-block"><b>Organization</b>: Solitary,
pair, or
herd (5&ndash;8)</span> <span className="stat-block"><b>Challenge Rating</b>:
7</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 11&ndash;20 HD (Huge);
21&ndash;30 HD (Gargantuan)</span> <span className="stat-block"><b>Level
Adjustment</b>: &mdash;</span>
    <p>Though it resides primarily in the water, an elasmosaurus only
breathes air. An elasmosaurus has a total length of some 30 feet,
including a tail half as long as its entire body, and weighs about
5,000 pounds. Observers who see only its head or tail might easily
mistake it for a massive snake.</p>
    <h6>Combat</h6>
    <p className="initial">An elasmosaurus is aggressive and attacks
anything it notices. The creature is strong, fast, and highly
maneuverable, able to turn quickly and lunge at prey. When hunting, it
travels with its head out of the water, snapping down quickly to seize
prey.</p>
    <p><b>Skills</b>: *An elasmosaurus has a +8 racial bonus on Hide
checks in water.</p>
    <h5><a id="megaraptor-dinosaur"></a>MEGARAPTOR</h5>
    <span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 8d8+43 (79 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: 60 ft. (12 squares)</span>
    <span className="stat-block"><b>Armor Class</b>: 17 (&ndash;1 size,
+2 Dex,
+6 natural), touch 11, flat-footed 15</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +6/+15</span> <span className="stat-block"><b>Attack</b>:
Talons +10 melee (2d6+5)</span> <span className="stat-block"><b>Full Attack</b>:
Talons +10 melee
(2d6+5) and 2 foreclaws +5 melee (1d4+2) and bite +5 melee (1d8+2)</span>
    <span className="stat-block"><b>Space/Reach</b>: 10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#pounce" style={{
        "color": "rgb(87, 158, 182)"
      }}>Pounce</a></span>
    <span className="stat-block"><b>Special Qualities</b>: Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +10,
Ref +8, Will +4</span> <span className="stat-block"><b>Abilities</b>: Str
21, Dex 15, Con
21, Int 2, Wis 15, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+9, <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
+27, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+12, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+12, <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> +12</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#run" style={{
        "color": "rgb(87, 158, 182)"
      }}>Run</a>, <a href="featsAll.html#toughness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a>,
      <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a></span>
    <span className="stat-block"><b>Environment</b>:
Warm forests</span> <span className="stat-block"><b>Organization</b>:
Solitary, pair, or
pack (3&ndash;6)</span> <span className="stat-block"><b>Challenge Rating</b>:
6</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 9&ndash;16 HD (Large);
17&ndash;24 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>: &mdash;</span>
    <p>This creature is a larger version of the deinonychus, standing
about 12 feet tall with a total length of 24 feet. It has the same
appearance, habits, and abilities of the smaller version.</p>
    <h6>Combat</h6>
    <b>Pounce (Ex)</b>: If a megaraptor charges, it can make a
full attack.
    <p><b>Skills</b>: A megaraptor has a +8 racial bonus on Hide,
Jump, Listen, Spot, and Survival checks.</p>
    <h5><a id="triceratops-dinosaur"></a>TRICERATOPS</h5>
    <span className="size-block">Huge <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 16d8+124 (196 hp)</span> <span className="stat-block"><b>Initiative</b>:
&ndash;1</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6
squares)</span> <span className="stat-block"><b>Armor Class</b>: 18
(&ndash;2 size, &ndash;1 Dex,
+11 natural), touch 7, flat-footed 18</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +12/+30</span> <span className="stat-block"><b>Attack</b>:
Gore +20 melee (2d8+15)</span> <span className="stat-block"><b>Full Attack</b>:
Gore +20 melee
(2d8+15)</span> <span className="stat-block"><b>Space/Reach</b>: 15 ft./10
ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#powerful-charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Powerful
charge</a>, <a href="monsterTypes.html#trample" style={{
        "color": "rgb(87, 158, 182)"
      }}>trample</a> 2d12+15</span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +19,
Ref +9, Will +6</span> <span className="stat-block"><b>Abilities</b>: Str
30, Dex 9, Con 25,
Int 1, Wis 12, Cha 7</span> <span className="stat-block"><b>Skills</b>: <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+13, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+12</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#great-fortitude" style={{
        "color": "rgb(87, 158, 182)"
      }}>Great
Fortitude</a>, <a href="featsAll.html#toughness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a> (4)</span> <span className="stat-block"><b>Environment</b>:
Temperate plains</span> <span className="stat-block"><b>Organization</b>:
Solitary, pair, or
herd (5&ndash;8)</span> <span className="stat-block"><b>Challenge Rating</b>:
9</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 17&ndash;32 HD (Huge);
33&ndash;48 HD (Gargantuan)</span> <span className="stat-block"><b>Level
Adjustment</b>: &mdash;</span>
    <p>A triceratops has a body about 25 feet long and weighs about
20,000 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">These creatures are likely to charge and
skewer any creature of at least Large size that infringes on their
territory. A triceratops uses its trample attack on smaller opponents.</p>
    <p><b>Powerful Charge (Ex)</b>: When a triceratops charges, its
gore attack deals 4d8+20 points of damage.</p>
    <p><b>Trample (Ex)</b>: Reflex half DC 28. The save DC is
Strength-based.</p>
    <h5><a id="tyrannosaurus"></a>TYRANNOSAURUS</h5>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG61.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Huge <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 18d8+99 (180 hp)</span> <span className="stat-block"><b>Initiative</b>:
+1</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares)</span>
    <span className="stat-block"><b>Armor Class</b>: 14 (&ndash;2 size,
+1 Dex,
+5 natural) touch 9, flat-footed 13</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +13/+30</span> <span className="stat-block"><b>Attack</b>:
Bite +20 melee (3d6+13)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +20 melee
(3d6+13)</span> <span className="stat-block"><b>Space/Reach</b>: 15 ft./10
ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
grab</a>, <a href="monsterTypes.html#swallow-whole" style={{
        "color": "rgb(87, 158, 182)"
      }}>swallow whole</a></span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +16,
Ref +12, Will +8</span> <span className="stat-block"><b>Abilities</b>: Str
28, Dex 12, Con
21, Int 2, Wis 15, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
&ndash;2, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+14, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+14</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="monsterFeats.html#improved-natural-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Natural Attack</a> (bite), <a href="featsAll.html#run" style={{
        "color": "rgb(87, 158, 182)"
      }}>Run</a>, <a href="featsAll.html#toughness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a>
(3), <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a></span>
    <span className="stat-block"><b>Environment</b>: Warm plains</span> <span className="stat-block"><b>Organization</b>: Solitary or pair</span> <span className="stat-block"><b>Challenge Rating</b>: 8</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 19&ndash;36 HD (Huge);
37&ndash;54 HD (Gargantuan)</span> <span className="stat-block"><b>Level
Adjustment</b>: &mdash;</span>
    <p>Despite its enormous size and 6-ton weight, a tyrannosaurus is
a swift runner. Its head is nearly 6 feet long, and its teeth are from
3 to 6 inches in length. It is slightly more than 30 feet long from
nose to tail.</p>
    <h6>Combat</h6>
    <p className="initial">A tyrannosaurus pursues and eats just about
anything it sees. Its tactics are simple&mdash;charge in and bite.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a
tyrannosaurus must hit an opponent of up to one size smaller with its
bite attack. It can then attempt to start a grapple as a free action
without provoking an attack of opportunity. If it wins the grapple
check, it establishes a hold and can try to swallow the foe the
following round.</p>
    <p><b>Swallow Whole (Ex)</b>: A tyrannosaurus can try to swallow
a grabbed opponent of up to two sizes smaller by making a successful
grapple check. The swallowed creature takes 2d8+8 points of bludgeoning
damage and 8 points of acid damage per round from the
tyrannosaurus&rsquo;s
gizzard. A swallowed creature can cut its way out by using a light
slashing or piercing weapon to deal 25 points of damage to the gizzard
(AC 12). Once the creature exits, muscular action closes the hole;
another swallowed opponent must cut its own way out.</p>
    <p>A Huge tyrannosaurus&rsquo;s gizzard can hold 2 Medium, 8
Small, 32
Tiny, or 128 Diminutive or smaller opponents.</p>
    <p><b>Skills</b>: A tyrannosaurus has a +2 racial bonus on Listen
and Spot checks.</p>
    <h3><a id="dire-animal"></a>DIRE ANIMAL</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Dire animals are larger, tougher, meaner
versions of ordinary animals. Each kind tends to have a feral,
prehistoric, or even demonic appearance.</p>
    <h5><a id="dire-ape"></a>DIRE APE</h5>
    <span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 5d8+13 (35 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares),
climb
15 ft.</span> <span className="stat-block"><b>Armor Class</b>: 15
(&ndash;1
size, +2 Dex,
+4 natural), touch 11, flat-footed 13</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +3/+13</span> <span className="stat-block"><b>Attack</b>:
Claw +8 melee (1d6+6)</span> <span className="stat-block"><b>Full Attack</b>:
2 claws +8 melee
(1d6+6) and bite +3 melee (1d8+3)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./10 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#rend" style={{
        "color": "rgb(87, 158, 182)"
      }}>Rend</a>
2d6+9</span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +6,
Ref +6, Will +5</span> <span className="stat-block"><b>Abilities</b>: Str
22, Dex 15, Con
14, Int 2, Wis 12, Cha 7</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
+14, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+5, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> +4, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+6</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#toughness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a></span> <span className="stat-block"><b>Environment</b>:
Warm forests</span> <span className="stat-block"><b>Organization</b>:
Solitary or company
(5&ndash;8)</span> <span className="stat-block"><b>Challenge Rating</b>: 3</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 6&ndash;15 HD (Large)</span> <span className="stat-block"><b>Level Adjustment</b>: &mdash;</span>
    <p>A dire ape stands about 9 feet tall and weighs from 800 to
1,200 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">Dire apes attack anything that enters their
territory, even other dire apes. If an opponent&rsquo;s armor foils a
dire
ape&rsquo;s attacks, the creature will attempt to grapple and pin, then
rend
the prone opponent.</p>
    <p><b>Rend (Ex)</b>: A dire ape that hits with both claw attacks
latches onto the opponent&rsquo;s body and tears the flesh. This attack
automatically deals an extra 2d6+12 points of damage.</p>
    <p><b>Skills</b>: Dire apes have a +8 racial bonus on Climb
checks and can always choose to take 10 on Climb checks, even if rushed
or threatened.</p>
    <h5><a id="dire-badger"></a>DIRE BADGER</h5>
    <span className="size-block">Medium <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span>
    <span className="stat-block"><b>Hit Dice</b>: 3d8+15 (28 hp)</span> <span className="stat-block"><b>Initiative</b>: +3</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares), burrow
10 ft.</span> <span className="stat-block"><b>Armor Class</b>: 16 (+3 Dex,
+3
natural), touch 13, flat-footed 13</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +2/+4</span> <span className="stat-block"><b>Attack</b>:
Claw +4 melee (1d4+2)</span> <span className="stat-block"><b>Full Attack</b>:
2 claws +4 melee
(1d4+2) and bite &ndash;1 melee (1d6+1)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Rage</span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +7,
Ref +6, Will +4</span> <span className="stat-block"><b>Abilities</b>: Str
14, Dex 17, Con
19, Int 2, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+6, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+6</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#toughness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a>, <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track<sup>B</sup></a></span>
    <span className="stat-block"><b>Environment</b>:
Temperate
forests</span> <span className="stat-block"><b>Organization</b>: Solitary
or cete
(2&ndash;5)</span> <span className="stat-block"><b>Challenge Rating</b>: 2</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 4&ndash;9 HD (Large)</span> <span className="stat-block"><b>Level Adjustment</b>: &mdash;</span>
    <p>These vicious creatures tolerate no intrusions. They cannot
burrow into solid rock, but can move through just about any material
softer than that. A dire badger usually leaves behind a usable tunnel 5
feet in diameter when burrowing unless the material it&rsquo;s moving
through
is very loose.</p>
    <p>A dire badger is from 5 to 7 feet in length and can weigh up
to 500 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">Dire badgers attack with their sharp claws and
teeth.</p>
    <p><b>Rage (Ex)</b>: A dire badger that takes damage in combat
flies into a berserk rage on its next turn, clawing and biting madly
until either it or its opponent is dead. It gains +4 Strength, +4
Constitution, and &ndash;2 AC. The creature cannot end its rage
voluntarily.</p>
    <h5><a id="dire-bat"></a>DIRE BAT</h5>
    <span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 4d8+12 (30 hp)</span> <span className="stat-block"><b>Initiative</b>:
+6</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares),
fly 40
ft. (good)</span> <span className="stat-block"><b>Armor Class</b>: 20
(&ndash;1
size, +6 Dex,
+5 natural), touch 15, flat-footed 14</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +3/+10</span> <span className="stat-block"><b>Attack</b>:
Bite +5 melee (1d8+4)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +5 melee (1d8+4)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
&mdash;</span> <span className="stat-block"><b>Special Qualities</b>: <a href="monsterTypes.html#blindsense" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blindsense</a>
40
ft.</span> <span className="stat-block"><b>Saves</b>: Fort +7, Ref +10,
Will +6</span> <span className="stat-block"><b>Abilities</b>: Str 17, Dex
22, Con
17, Int 2, Wis 14, Cha 6</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+4, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+12*, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> +11, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+8*</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#stealthy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Stealthy</a></span>
    <span className="stat-block"><b>Environment</b>:
Temperate deserts</span> <span className="stat-block"><b>Organization</b>:
Solitary or colony
(5&ndash;8)</span> <span className="stat-block"><b>Challenge Rating</b>: 2</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 5&ndash;12 HD (Large)</span> <span className="stat-block"><b>Level Adjustment</b>: &mdash;</span>
    <p>A dire bat has a wingspan of 15 feet and weighs about 200
pounds.</p>
    <h6>Combat</h6>
    <p className="initial">Dire bats swoop down upon unsuspecting prey
from above.</p>
    <p><b>Blindsense (Ex)</b>: A dire bat uses echolocation to
pinpoint creatures within 40 feet. Opponents still have total
concealment against the bat unless it can actually see them.</p>
    <p><b>Skills</b>: Dire bats have a +4 racial bonus on Spot and
Listen checks. These bonuses are lost if its blindsense is negated.</p>
    <h5><a id="dire-bear"></a>DIRE BEAR</h5>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG63.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 12d8+51 (105 hp)</span> <span className="stat-block"><b>Initiative</b>:
+1</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares)</span>
    <span className="stat-block"><b>Armor Class</b>: 17 (&ndash;1 size,
+1 Dex,
+7 natural), touch 10, flat-footed 16</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +9/+23</span> <span className="stat-block"><b>Attack</b>:
Claw +19 melee (2d4+10)</span> <span className="stat-block"><b>Full Attack</b>:
2 claws +19 melee
(2d4+10) and bite +13 melee (2d8+5)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved grab</a></span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +12,
Ref +9, Will +9</span> <span className="stat-block"><b>Abilities</b>: Str
31, Dex 13, Con
19, Int 2, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+10, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+10, <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
+13</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#endurance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Endurance</a>, <a href="featsAll.html#run" style={{
        "color": "rgb(87, 158, 182)"
      }}>Run</a>, <a href="featsAll.html#toughness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a>,
      <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Focus</a> (claw)</span> <span className="stat-block"><b>Environment</b>:
Cold forests</span> <span className="stat-block"><b>Organization</b>:
Solitary or pair</span> <span className="stat-block"><b>Challenge Rating</b>:
7</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 13&ndash;16 HD (Large);
17&ndash;36 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>The omnivorous dire bear usually does not bother creatures
that try to avoid it, but will aggressively defend a kill or other
source of food. It will not hesitate to rip apart anything that might
contain something edible.</p>
    <p>A typical dire bear is 12 feet long and weighs as much as
8,000 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">A dire bear attacks by tearing at opponents
with its claws.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a dire bear
must hit with a claw attack. It can then attempt to start a grapple as
a free action without provoking an attack of opportunity.</p>
    <h5><a id="dire-boar"></a>DIRE BOAR</h5>
    <span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 7d8+21 (52 hp)</span> <span className="stat-block"><b>Initiative</b>:
+0</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares)</span>
    <span className="stat-block"><b>Armor Class</b>: 15 (&ndash;1 size,
+6
natural), touch 9, flat-footed 15</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +5/+17</span> <span className="stat-block"><b>Attack</b>:
Gore +12 melee (1d8+12)</span> <span className="stat-block"><b>Full Attack</b>:
Gore +12 melee
(1d8+12)</span> <span className="stat-block"><b>Space/Reach</b>: 10 ft./5
ft.</span> <span className="stat-block"><b>Special Attacks</b>: Ferocity</span>
    <span className="stat-block"><b>Special Qualities</b>: Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +8,
Ref +5, Will +8</span> <span className="stat-block"><b>Abilities</b>: Str
27, Dex 10, Con
17, Int 2, Wis 13, Cha 8</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+8, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+8</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#endurance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Endurance</a>, <a href="featsAll.html#iron-will" style={{
        "color": "rgb(87, 158, 182)"
      }}>Iron
Will</a></span> <span className="stat-block"><b>Environment</b>: Temperate
forests</span> <span className="stat-block"><b>Organization</b>: Solitary
or herd
(5&ndash;8)</span> <span className="stat-block"><b>Challenge Rating</b>: 4</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 8&ndash;16 HD (Large);
17&ndash;21 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>Dire boars are omnivorous and spend most of their time rooting
around, much as ordinary pigs do. They viciously attack anything that
approaches them, however.</p>
    <p>Dire boars grow up to 12 feet long and weigh as much as 2,000
pounds.</p>
    <h6>Combat</h6>
    <p className="initial">A dire boar charges its opponent, trying to
rip the target open with its tusks.</p>
    <p><b>Ferocity (Ex)</b>: A dire boar is such a tenacious
combatant that it continues to fight without penalty even while
disabled or dying.</p>
    <h5><a id="dire-lion"></a>DIRE LION</h5>
    <span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 8d8+24 (60 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares)</span>
    <span className="stat-block"><b>Armor Class</b>: 15 (&ndash;1 size,
+2 Dex,
+4 natural), touch 11, flat-footed 13</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +6/+17</span> <span className="stat-block"><b>Attack</b>:
Claw +13 melee (1d6+7)</span> <span className="stat-block"><b>Full Attack</b>:
2 claws +13 melee
(1d6+7) and bite +7 melee (1d8+3)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved grab</a>, <a href="monsterTypes.html#pounce" style={{
        "color": "rgb(87, 158, 182)"
      }}>pounce</a>,
      <a href="monsterTypes.html#rake" style={{
        "color": "rgb(87, 158, 182)"
      }}>rake</a>
1d6+3</span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +9,
Ref +8, Will +7</span> <span className="stat-block"><b>Abilities</b>: Str
25, Dex 15, Con
17, Int 2, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+2*, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+7, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> +5, Spot +7</span> <span className="stat-block"><b>Feats</b>:
      <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>, <a href="featsAll.html#run" style={{
        "color": "rgb(87, 158, 182)"
      }}>Run</a>, <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Focus</a> (claw)</span> <span className="stat-block"><b>Environment</b>:
Warm
plains</span> <span className="stat-block"><b>Organization</b>: Solitary,
pair, or
pride (6&ndash;10)</span> <span className="stat-block"><b>Challenge Rating</b>:
5</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 9&ndash;16 HD (Large);
17&ndash;24 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>Dire lions are patient hunters, just like their smaller
cousins, but apt to take on bigger prey.</p>
    <p>Dire lions grow to be up to 15 feet long and weigh up to 3,500
pounds.</p>
    <h6>Combat</h6>
    <p className="initial">A dire lion attacks by running at prey,
leaping, and clawing and biting as it rakes with its rear claws. It
often jumps onto a creature larger than itself.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a dire lion
must hit with its bite attack. It can then attempt to start a grapple
as a free action without provoking an attack of opportunity. If it wins
the grapple check, it establishes a hold and can rake.</p>
    <p><b>Pounce (Ex)</b>: If a dire lion charges, it can make a full
attack, including two rake attacks.</p>
    <p><b>Rake (Ex)</b>: Attack bonus +12 melee, damage 1d6+3.</p>
    <p><b>Skills</b>: Dire lions have a +4 racial bonus on Hide and
Move Silently checks. </p>
    <p>*In areas of tall grass or heavy undergrowth, the Hide bonus
improves to +8.</p>
    <h5><a id="dire-rat"></a>DIRE RAT</h5>
    <span className="size-block">Small <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 1d8+1 (5 hp)</span> <span className="stat-block"><b>Initiative</b>:
+3</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares),
climb
20 ft.</span> <span className="stat-block"><b>Armor Class</b>: 15 (+1
size, +3 Dex,
+1 natural), touch 14, flat-footed 12</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +0/&ndash;4</span> <span className="stat-block"><b>Attack</b>:
Bite +4 melee (1d4 plus
disease)</span> <span className="stat-block"><b>Full Attack</b>: Bite +4
melee (1d4
plus disease)</span> <span className="stat-block"><b>Space/Reach</b>: 5
ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Disease</span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +3,
Ref +5, Will +3</span> <span className="stat-block"><b>Abilities</b>: Str
10, Dex 17, Con
12, Int 1, Wis 12, Cha 4</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
+11, <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+8, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+4, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> +4, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+4, <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
+11</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#weapon-finesse" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Finesse<sup>B</sup></a></span>
    <span className="stat-block"><b>Environment</b>: Any</span> <span className="stat-block"><b>Organization</b>: Solitary or pack
(11&ndash;20)</span> <span className="stat-block"><b>Challenge Rating</b>:
1/3</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 2&ndash;3 HD (Small); 4&ndash;6
HD (Medium)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>Dire rats are omnivorous scavengers, but will attack to defend
their nests and territories.</p>
    <p>A dire rat can grow to be up to 4 feet long and weigh over 50
pounds.</p>
    <h6>Combat</h6>
    <p className="initial">Dire rat packs attack fearlessly, biting and
chewing with their sharp incisors.</p>
    <p><b>Disease (Ex)</b>: Filth fever&mdash;bite, Fortitude DC 11,
incubation period 1d3 days, damage 1d3 Dex and 1d3 Con. The save DC is
Constitution-based.</p>
    <p><b>Skills</b>: Dire rats have a +8 racial bonus on Swim
checks. Dire rats have a +8 racial bonus on Climb checks and can always
choose to take 10 on Climb checks, even if rushed or threatened.</p>
    <p>Dire rats use their Dexterity modifier for Climb and Swim
checks.</p>
    <h5><a id="dire-shark"></a>DIRE SHARK</h5>
    <span className="size-block">Huge <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a> (<a href="monsterTypes.html#aquatic" style={{
        "color": "black"
      }}>Aquatic</a>)</span> <span className="stat-block"><b>Hit
Dice</b>: 18d8+66 (147 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: Swim 60 ft. (12
squares)</span> <span className="stat-block"><b>Armor Class</b>: 17
(&ndash;2
size, +2 Dex,
+7 natural), touch 10, flat-footed 15</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +13/+27</span> <span className="stat-block"><b>Attack</b>:
Bite +18 melee (2d8+9)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +18 melee
(2d8+9)</span> <span className="stat-block"><b>Space/Reach</b>: 15 ft./10
ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
grab</a>, <a href="monsterTypes.html#swallow-whole" style={{
        "color": "rgb(87, 158, 182)"
      }}>swallow whole</a></span> <span className="stat-block"><b>Special Qualities</b>:
Keen scent</span> <span className="stat-block"><b>Saves</b>: Fort +14, Ref
+13, Will +12</span> <span className="stat-block"><b>Abilities</b>: Str
23, Dex 15, Con
17, Int 1, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+12, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+11, <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
+14</span> <span className="stat-block"><b>Feats</b>: <a href="monsterFeats.html#improved-natural-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Natural Attack</a>
(bite), <a href="featsAll.html#tuoghness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a> (4), <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Focus</a> (bite)</span> <span className="stat-block"><b>Environment</b>:
Cold aquatic</span> <span className="stat-block"><b>Organization</b>:
Solitary or school
(2&ndash;5)</span> <span className="stat-block"><b>Challenge Rating</b>: 9</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 19&ndash;32 (Huge); 33&ndash;54
(Gargantuan)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>Dire sharks attack anything they perceive to be edible, even
larger creatures. </p>
    <p>This monstrous fish can grow to a length of 25 feet and weigh
more than 20,000 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">Dire sharks bite with their powerful jaws,
swallowing smaller creatures in one gulp.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a dire shark
must hit with its bite attack. It can then attempt to start a grapple
as a free action without provoking an attack of opportunity. If it wins
the grapple check, it establishes a hold and can try to swallow the foe
in the following round.</p>
    <p><b>Swallow Whole (Ex)</b>: A dire shark can try to swallow a
grabbed opponent of up to one size smaller by making a successful
grapple check. Once inside, the opponent takes 2d6+6 points of
bludgeoning damage plus 1d8+4 points of acid damage per round from the
shark&rsquo;s digestive juices. A swallowed creature can cut its way
out
using a light slashing or piercing weapon by dealing 25 points of
damage to the shark&rsquo;s digestive tract (AC 13). Once the creature
exits,
muscular action closes the hole; another swallowed opponent must cut
its own way out.</p>
    <p>A Huge dire shark&rsquo;s gullet can hold 2 Large, 8 Medium or
Small, 32 Tiny, 128 Diminutive, or 512 Fine or smaller opponents.</p>
    <p><b>Keen Scent (Ex)</b>: A dire shark can notice creatures by
scent in a 180-foot radius and can detect blood in the water at a range
of up to 1 mile.</p>
    <p><b>Skills</b>: A dire shark has a +8 racial bonus on any Swim
check to perform some special action or avoid a hazard. It can always
choose to take 10 on a Swim check, even if distracted or endangered. It
can use the run action while swimming, provided it swims in a straight
line.</p>
    <h5><a id="dire-tiger"></a>DIRE TIGER</h5>
    <span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 16d8+48 (120 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares)</span>
    <span className="stat-block"><b>Armor Class</b>: 17 (&ndash;1 size,
+2 Dex,
+6 natural), touch 11, flat-footed 15</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +12/+24</span> <span className="stat-block"><b>Attack</b>:
Claw +20 melee (2d4+8)</span> <span className="stat-block"><b>Full Attack</b>:
2 claws +20 melee
(2d4+8) and bite +14 melee (2d6+4)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved grab</a>, <a href="monsterTypes.html#pounce" style={{
        "color": "rgb(87, 158, 182)"
      }}>pounce</a>,
      <a href="monsterTypes.html#rake" style={{
        "color": "rgb(87, 158, 182)"
      }}>rake</a>
2d4+4</span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +13,
Ref +12, Will +11</span> <span className="stat-block"><b>Abilities</b>:
Str 27, Dex 15, Con
17, Int 2, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+7*, <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
+14, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+6, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> +11, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+7, <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
+10</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="monsterFeats.html#improved-natural-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Natural Attack</a> (claw), <a href="featsAll.html#improved-natural-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Natural Attack</a> (bite), <a href="featsAll.html#run" style={{
        "color": "rgb(87, 158, 182)"
      }}>Run</a>, <a href="featsAll.html#stealthy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Stealthy</a>,
      <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Focus</a> (claw)</span> <span className="stat-block"><b>Environment</b>:
Warm forests</span> <span className="stat-block"><b>Organization</b>:
Solitary or pair</span> <span className="stat-block"><b>Challenge Rating</b>:
8</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 17&ndash;32 HD (Large);
33&ndash;48 (Huge)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>Dire tigers prey on just about anything that moves. They will
patiently stalk a potential meal, striking whenever the creature lets
down its guard.</p>
    <p>Dire tigers grow to be over 12 feet long and can weigh up to
6,000 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">A dire tiger attacks by running at prey,
leaping, and clawing and biting as it rakes with its rear claws.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a dire tiger
must hit with its bite attack. It can then attempt to start a grapple
as a free action without provoking an attack of opportunity. If it wins
the grapple check, it establishes a hold and can rake.</p>
    <p><b>Pounce (Ex)</b>: If a dire tiger charges, it can make a
full attack, including two rake attacks.</p>
    <p><b>Rake (Ex)</b>: Attack bonus +18 melee, damage 2d4+4.</p>
    <p><b>Skills</b>: Dire tigers have a +4 racial bonus on Hide and
Move Silently checks. *In areas of tall grass or heavy undergrowth, the
Hide bonus improves to +8.</p>
    <h5><a id="dire-weasel"></a>DIRE WEASEL</h5>
    <span className="size-block">Medium <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span>
    <span className="stat-block"><b>Hit Dice</b>: 3d8 (13 hp)</span> <span className="stat-block"><b>Initiative</b>: +4</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares)</span> <span className="stat-block"><b>Armor Class</b>: 16 (+4 Dex, +2
natural), touch 14, flat-footed 12</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +2/+4</span> <span className="stat-block"><b>Attack</b>:
Bite +6 melee (1d6+3)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +6 melee (1d6+3)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Attach, blood
drain</span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +3,
Ref +7, Will +4</span> <span className="stat-block"><b>Abilities</b>: Str
14, Dex 19, Con
10, Int 2, Wis 12, Cha 11</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+8, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+3, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> +8, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> +5</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>, <a href="featsAll.html#stealthy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Stealthy</a>,
      <a href="featsAll.html#weapon-finesse" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Finesse<sup>B</sup></a></span>
    <span className="stat-block"><b>Environment</b>:
Temperate hills</span> <span className="stat-block"><b>Organization</b>:
Solitary or pair</span> <span className="stat-block"><b>Challenge Rating</b>:
2</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 4&ndash;6 HD (Medium);
7&ndash;9
HD (Large)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>Dire weasels grow to be up to 10 feet long and can reach a
weight of 700 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">Dire weasels stalk their prey in the dark and
then leap on it, biting and clawing.</p>
    <p><b>Attach (Ex)</b>: A dire weasel that hits with its bite
attack latches onto the opponent&rsquo;s body with its powerful jaws.
An
attached dire weasel loses its Dexterity bonus to AC and thus has an AC
of 12. An attached dire weasel can be struck with a weapon or grappled
itself. To remove an attached dire weasel through grappling, the
opponent must achieve a pin against the creature.</p>
    <p><b>Blood Drain (Ex)</b>: A dire weasel drains blood for 1d4
points of Constitution damage each round it remains attached.</p>
    <h5><a id="dire-wolf"></a>DIRE WOLF</h5>
    <span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 6d8+18 (45 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: 50 ft. (10 squares)</span>
    <span className="stat-block"><b>Armor Class</b>: 14 (&ndash;1 size,
+2 Dex,
+3 natural), touch 11, flat-footed 12</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +4/+15</span> <span className="stat-block"><b>Attack</b>:
Bite +11 melee (1d8+10)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +11 melee
(1d8+10)</span> <span className="stat-block"><b>Space/Reach</b>: 10 ft./5
ft.</span> <span className="stat-block"><b>Special Attacks</b>: Trip</span>
    <span className="stat-block"><b>Special Qualities</b>: Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +8,
Ref +7, Will +6</span> <span className="stat-block"><b>Abilities</b>: Str
25, Dex 15, Con
17, Int 2, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+0, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+7, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> +4, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> +7, <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a>
+2*</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#run" style={{
        "color": "rgb(87, 158, 182)"
      }}>Run</a>,
      <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track<sup>B</sup></a>,
      <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Focus</a> (bite)</span> <span className="stat-block"><b>Environment</b>:
Temperate forests</span> <span className="stat-block"><b>Organization</b>:
Solitary or pack
(5&ndash;8)</span> <span className="stat-block"><b>Challenge Rating</b>: 3</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 7&ndash;18 HD (Large)</span> <span className="stat-block"><b>Level Adjustment</b>: &mdash;</span>
    <p>Dire wolves are efficient pack hunters that will kill anything
they can catch.</p>
    <p>Dire wolves are mottled gray or black, about 9 feet long and
weighing some 800 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">Dire wolves prefer to attack in packs,
surrounding and flanking a foe when they can.</p>
    <p><b>Trip (Ex)</b>: A dire wolf that hits with a bite attack can
attempt to trip its opponent (+11 check modifier) as a free action
without making a touch attack or provoking an attack of opportunity. If
the attempt fails, the opponent cannot react to trip the dire wolf.</p>
    <p><b>Skills</b>: A dire wolf has a +2 racial bonus on Hide,
Listen, Move Silently, and Spot checks. </p>
    <p>*It also has a +4 racial bonus on Survival checks when
tracking by scent.</p>
    <h5><a id="dire-wolverine"></a>DIRE WOLVERINE</h5>
    <span className="size-block">Large <a href="monsterTypes.html#animal" style={{
        "color": "black"
      }}>Animal</a></span> <span className="stat-block"><b>Hit
Dice</b>: 5d8+23 (45 hp)</span> <span className="stat-block"><b>Initiative</b>:
+3</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares),
climb
10 ft.</span> <span className="stat-block"><b>Armor Class</b>: 16
(&ndash;1
size, +3 Dex,
+4 natural), touch 12, flat-footed 13</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +3/+13</span> <span className="stat-block"><b>Attack</b>:
Claw +8 melee (1d6+6)</span> <span className="stat-block"><b>Full Attack</b>:
2 claws +8 melee
(1d6+6) and bite +3 melee (1d8+3)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Rage</span> <span className="stat-block"><b>Special Qualities</b>:
Low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +8,
Ref +7, Will +5</span> <span className="stat-block"><b>Abilities</b>: Str
22, Dex 17, Con
19, Int 2, Wis 12, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
+14, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+7, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+7</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#toughness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Toughness</a>, <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track<sup>B</sup></a></span>
    <span className="stat-block"><b>Environment</b>:
Cold forests</span> <span className="stat-block"><b>Organization</b>:
Solitary or pair</span> <span className="stat-block"><b>Challenge Rating</b>:
4</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 6&ndash;15 HD (Large)</span> <span className="stat-block"><b>Level Adjustment</b>: &mdash;</span>
    <p>Dire wolverines grow to about 12 feet in length and can weigh
as much as 2,000 pounds.</p>
    <h6>Combat</h6>
    <p className="initial">Dire wolverines attack opponents wantonly,
fearing no other creatures.</p>
    <p><b>Rage (Ex)</b>: A dire wolverine that takes damage in combat
flies into a berserk rage on its next turn, clawing and biting madly
until either it or its opponent is dead. An enraged dire wolverine
gains +4 Strength, +4 Constitution, and &ndash;2 AC. The creature
cannot end
its rage voluntarily.</p>
    <p><b>Skills</b>: A dire wolverine has a +8 racial bonus on Climb
checks and can always choose to take 10 on Climb checks, even if rushed
or threatened.</p>
    <h3><a id="doppelganger"></a>DOPPELGANGER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG67b.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Medium <a href="monsterTypes.html#monstrous-humanoid" style={{
        "color": "black"
      }}>Monstrous
Humanoid</a>
(<a href="monsterTypes.html#shapechanger" style={{
        "color": "black"
      }}>Shapechanger</a>)</span>
    <span className="stat-block"><b>Hit Dice</b>: 4d8+4
(22 hp)</span> <span className="stat-block"><b>Initiative</b>: +1</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares)</span> <span className="stat-block"><b>Armor Class</b>: 15 (+1 Dex, +4
natural), touch 11, flat-footed 14</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +4/+5</span> <span className="stat-block"><b>Attack</b>:
Slam +5 melee (1d6+1)</span> <span className="stat-block"><b>Full Attack</b>:
Slam +5 melee (1d6+1)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="spellsDtoE.html#detect-thoughts" style={{
        "color": "rgb(87, 158, 182)"
      }}>Detect
thoughts</a></span> <span className="stat-block"><b>Special Qualities</b>:
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#change-shape">Change shape</a>,
immunity to sleep and charm effects</span> <span className="stat-block"><b>Saves</b>:
Fort +4, Ref +5, Will +6</span> <span className="stat-block"><b>Abilities</b>:
Str 12, Dex 13, Con
12, Int 13, Wis 14, Cha 13</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
+10*, <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> +3, <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a>
+9*
(+11 acting), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a> +3, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+6, <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> +6, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+6</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>,
      <a href="featsAll.html#great-fortitude" style={{
        "color": "rgb(87, 158, 182)"
      }}>Great Fortitude</a></span> <span className="stat-block"><b>Environment</b>: Any</span> <span className="stat-block"><b>Organization</b>: Solitary, pair, or
gang (3&ndash;6)</span> <span className="stat-block"><b>Challenge Rating</b>:
3</span> <span className="stat-block"><b>Treasure</b>: Double standard</span>
    <span className="stat-block"><b>Alignment</b>: Usually neutral</span>
    <span className="stat-block"><b>Advancement</b>: By character class</span>
    <span className="stat-block"><b>Level Adjustment</b>: +4</span>
    <p>Doppelgangers are strange beings that are able to take on the
shapes of those they encounter. In its natural form, the creature looks
more or less humanoid, but slender and frail, with gangly limbs and
half-formed features. The flesh is pale and hairless. Its large,
bulging eyes are yellow with slitted pupils.</p>
    <p>A doppelganger&rsquo;s appearance is deceiving even when
it&rsquo;s in its
true form. A doppelganger is hardy, with a natural agility not in
keeping with its frail appearance. </p>
    <p>Doppelgangers make excellent use of their natural mimicry to
stage ambushes, bait traps, and infiltrate humanoid society. Although
not usually evil, they are interested only in themselves and regard all
others as playthings to be manipulated and deceived.</p>
    <p>It is natural form a doppelganger is about 5-1/2 feet tall and
weighs about 150 pounds.</p>
    <h5>COMBAT</h5>
    <p className="initial">When in its natural form, a doppelganger
strikes with its powerful fists. In the shape of a warrior or some
other armed person, it attacks with whatever weapon is appropriate. In
such cases, it uses its <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-thoughts">detect thoughts</a> ability to
employ the same
tactics and strategies as the person it is impersonating.</p>
    <p><b>Detect Thoughts (Su)</b>: A doppelganger can continuously
use <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-thoughts">detect thoughts</a> as the
spell (caster level 18th; Will DC 13
negates). It can suppress or resume this ability as a free action. The
save DC is Charisma-based.</p>
    <p><b>Change Shape (Su)</b>: A doppelganger can assume the shape
of any Small or Medium humanoid. In humanoid form, the doppelganger
loses its natural attacks. A doppelganger can remain in its humanoid
form until it chooses to assume a new one. A change in form cannot be <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#dispel-magic">dispelled</a>,
but a doppelganger
reverts to its natural form when killed.
A <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> spell or ability
reveals its natural form.</p>
    <p><b>Skills</b>: A doppelganger has a +4 racial bonus on <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#bluff">Bluff</a>
and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#disguise">Disguise</a>
checks. </p>
    <p>*When using its change shape ability, a doppelganger gets an
additional +10 circumstance bonus on <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#disguise">Disguise</a>
checks. If it can read an
opponent&rsquo;s mind, it gets a further +4 circumstance bonus on <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#bluff">Bluff</a>
and
Disguise checks.</p>
    <h5>DOPPELGANGERS AS CHARACTERS</h5>
    <p className="initial">Doppelganger characters possess the following
racial traits. </p>
    <ul>
      <li>
        <p>+2 Strength, +2, Dexterity, +2 Constitution, +2
Intelligence, +4 Wisdom, +2 Charisma.</p>
      </li>
      <li>
        <p>Medium size.</p>
      </li>
      <li>
        <p>A doppelganger&rsquo;s base land speed is 30 feet.</p>
      </li>
      <li>
        <p>Darkvision: Doppelgangers can see in the dark up to 60
feet.</p>
      </li>
      <li>
        <p>Racial Hit Dice: A doppelganger begins with four levels of
monstrous humanoid, which provide 4d8 Hit Dice, a base attack bonus of
+4, and base saving throw bonuses of Fort +1, Ref +4, and Will +4.</p>
      </li>
      <li>
        <p>Racial Skills: A doppelganger&rsquo;s monstrous humanoid
levels
give it skill points equal to 7 x (2 + Int modifier). Its class skills
are <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#bluff">Bluff</a>,
          <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#diplomacy">Diplomacy</a>, <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#disguise">Disguise</a>,
          <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#intimidate">Intimidate</a>, <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#listen">Listen</a>,
          <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#sense-motive">Sense Motive</a>, and <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#spot">Spot</a>.</p>
      </li>
      <li>
        <p>Racial Feats: A doppelganger&rsquo;s monstrous humanoid
levels
give
it two feats.</p>
      </li>
      <li>
        <p>+4 natural armor bonus.</p>
      </li>
      <li>
        <p>+4 racial bonus on <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#bluff">Bluff</a> and <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#disguise">Disguise</a>
checks. When using
its
change shape ability, a doppelganger gets an additional +10
circumstance bonus on Disguise checks. If it can read an
opponent&rsquo;s
mind, it gets a further +4 circumstance bonus on Bluff and Disguise
checks.</p>
      </li>
      <li>
        <p>Special Attacks (see above): <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsDtoE.html#detect-thoughts">Detect thoughts</a>.</p>
      </li>
      <li>
        <p>Special Qualities (see above): <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="monsterTypes.html#change-shape">Change
shape</a>, immunity to
sleep and charm effects.</p>
      </li>
      <li>
        <p>Automatic Languages: Common. Bonus Languages: Auran,
Dwarven,
Elven, Gnome, Halfling, Giant, Terran.</p>
      </li>
      <li>
        <p>Favored Class: <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="rogue.html">Rogue</a>.</p>
      </li>
      <li>
        <p>Level adjustment +4. </p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      